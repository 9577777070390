* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(-45deg, #ff00ff, #00ffff, #ff00ff, #ffff00);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  color: #ffffff;
  overflow-x: hidden;
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header {
  text-align: center;
  margin-bottom: 40px;
}

h1 {
  font-family: 'Bangers', cursive;
  font-size: 4rem;
  text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff;
  animation: textGlow 2s ease-in-out infinite alternate;
}

@keyframes textGlow {
  from { text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff, 0 0 30px #ff00ff; }
  to { text-shadow: 0 0 20px #ff00ff, 0 0 30px #ff00ff, 0 0 40px #ff00ff; }
}

.tagline {
  font-size: 1.5rem;
  margin-top: 10px;
}

.meme-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.meme-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
  transition: transform 0.3s ease;
}

.meme-item:hover {
  transform: scale(1.05);
}

.meme-item img {
  width: 100%;
  height: auto;
  display: block;
}

.meme-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 10px;
  text-align: center;
  font-weight: bold;
}

.play-button {
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  border-radius: 50px;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}
.leaderboard {
margin-top: 40px;
text-align: center;
}

.leaderboard h2 {
font-family: 'Bangers', cursive;
font-size: 2.5rem;
margin-top: 40px;
text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff;
}

#leaderboardTable {
  width: 100%;
  border-collapse: collapse;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

#leaderboardTable th, #leaderboardTable td {
padding: 10px;
text-align: center;
border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

#leaderboardTable th {
background-color: rgba(255, 255, 255, 0.2);
font-weight: bold;
}

#leaderboardTable tr:nth-child(even) {
background-color: rgba(255, 255, 255, 0.05);
}

.play-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.8);
}

.game-section {
  margin-top: 40px;
  text-align: center;
}

.game-frame {
  width: 100%;
  max-width: 800px;
  height: 600px;
  border: 5px solid #ff00ff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
}

.high-scores {
  margin-top: 40px;
}

.high-scores h2 {
  font-family: 'Bangers', cursive;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff;
}

.score-list {
  list-style-type: none;
  padding: 0;
}

.score-item {
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-form {
  margin-top: 40px;
  text-align: center;
}

.wallet-form input {
  padding: 10px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.wallet-form button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #ff00ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.mute-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.mute-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.wallet-form button:hover {
  background-color: #ff66ff;
}

.winners-section {
  margin-top: 40px;
}

.winners-section h2 {
  font-family: 'Bangers', cursive;
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow: 0 0 10px #ffff00, 0 0 20px #ffff00;
}

.winner-list {
  list-style-type: none;
  padding: 0;
}

.winner-item {
  background-color: rgba(255, 255, 255, 0.1);
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.winner-badge {
  background-color: #ffff00;
  color: #000000;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 0.8rem;
}

@media (max-width: 768px) {
  h1 {
      font-size: 3rem;
  }

  .tagline {
      font-size: 1.2rem;
  }

  .meme-gallery {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .play-button {
      font-size: 1.2rem;
      padding: 12px 24px;
  }

  .game-frame {
      height: 400px;
  }
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto 40px;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.8);
}

.money-printer-video {
  width: 100%;
  height: auto;
  display: block;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.video-container:hover .video-overlay {
  opacity: 1;
}

.video-text {
  font-family: 'Bangers', cursive;
  font-size: 3rem;
  color: #ffffff;
  text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff;
  transform: scale(0.8);
  transition: transform 0.3s ease;
}

.video-container:hover .video-text {
  transform: scale(1);
}

@keyframes floatingMoney {
  0% { transform: translateY(0) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(10deg); }
  100% { transform: translateY(0) rotate(0deg); }
}

.floating-money {
  position: fixed;
  font-size: 2rem;
  animation: floatingMoney 3s ease-in-out infinite;
  pointer-events: none;
}

.meme-ticker {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 0, 255, 0.8);
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
}

.meme-ticker-content {
  display: inline-block;
  animation: tickerScroll 20s linear infinite;
}

@keyframes tickerScroll {
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
}

.glitch-effect {
  position: relative;
  animation: glitch 1s infinite;
}

@keyframes glitch {
  0% { transform: translate(0); }
  20% { transform: translate(-5px, 5px); }
  40% { transform: translate(-5px, -5px); }
  60% { transform: translate(5px, 5px); }
  80% { transform: translate(5px, -5px); }
  100% { transform: translate(0); }
}

.fling-info {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 30px;
  margin-top: 40px;
  text-align: center;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
  transition: transform 0.3s ease;
}

.fling-info:hover {
  transform: scale(1.02);
}

.fling-info h2 {
  font-family: 'Bangers', cursive;
  font-size: 3rem;
  margin-bottom: 20px;
  color: #00ffff;
}

.fling-info p {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.fling-info ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 30px;
}

.fling-info li {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.token-stats {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-label {
  font-size: 0.9rem;
  color: #00ffff;
}

.stat-value {
  font-size: 1.2rem;
  font-weight: bold;
}

.buy-button {
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  border: none;
  color: white;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.buy-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(255, 0, 255, 0.4);
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}
.token-metrics {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.token-metrics {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}
.token-list {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.token-item {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  flex: 1 1 200px;
  max-width: 250px;
}

.token-item h4 {
  margin-bottom: 10px;
  color: #00ffff;
}

.player-name-input {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
  max-width: 400px;
  margin: 50px auto;
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.title {
  font-family: 'Bangers', cursive;
  font-size: 2.5rem;
  color: #00ffff;
  margin-bottom: 20px;
  text-shadow: 0 0 10px #ff00ff, 0 0 20px #ff00ff;
}

.name-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 5px;
  color: #ffffff;
  margin-bottom: 20px;
}

.name-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.submit-button, .skip-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-button {
  background-color: #ff00ff;
  color: #ffffff;
}

.skip-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}

.submit-button:hover, .skip-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(255, 0, 255, 0.4);
}

.mode-toggle-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.mode-toggle-button:hover {
  background-color: #45a049;
}

.game-description {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.insane-description, .normal-description {
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.insane-description {
  color: #ff00ff;
}

.normal-description {
  color: #00ff00;
}

.game-description-container {
  max-width: 800px;
  margin: 40px auto;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
  padding: 30px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
}

.game-description-title {
  font-size: 36px;
  color: #00ff00;
  margin-bottom: 20px;
  text-shadow: 0 0 10px #00ff00;
}

.mode-toggle-container {
  margin-bottom: 30px;
}

.mode-toggle-button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: bold;
}

.mode-toggle-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
  box-shadow: 0 0 15px rgba(76, 175, 80, 0.5);
}

.game-description {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 30px;
  animation: fadeIn 0.5s;
}

.mode-title {
  font-size: 28px;
  margin-bottom: 20px;
  text-shadow: 0 0 10px currentColor;
}

.feature-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
  max-width: 500px;
  margin: 20px auto;
}

.feature-list li {
  margin-bottom: 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.emoji {
  font-size: 24px;
  margin-right: 10px;
  display: inline-block;
  width: 30px;
  text-align: center;
}

.warning {
  font-style: italic;
  color: #ff6b6b;
  margin-top: 20px;
}

.insane-description {
  color: #ff00ff;
}

.normal-description {
  color: #00ff00;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 600px) {
  .game-description-container {
    padding: 20px;
  }

  .game-description-title {
    font-size: 28px;
  }

  .mode-toggle-button {
    font-size: 16px;
    padding: 12px 24px;
  }
}

.gameplay-instructions, .power-ups-section {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
}

.instruction-list, .power-ups-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
  max-width: 500px;
  margin: 20px auto;
}

.instruction-list li, .power-ups-list li {
  margin-bottom: 15px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.power-ups-section h3, .gameplay-instructions h3 {
  color: #ffd700;
  font-size: 24px;
  margin-bottom: 15px;
}