
.game-story {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 15px;
    padding: 20px;
    margin: 20px 0;
    color: #fff;
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  
  .game-story h2 {
    color: #ff00ff;
    font-size: 24px;
    margin-bottom: 15px;
    text-shadow: 0 0 10px #ff00ff;
  }
  
  .story-content {
    overflow: hidden;
    font-size: 18px;
    line-height: 1.5;
  }
  
  .game-story button {
    background-color: #00ff00;
    border: none;
    color: #000;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 25px;
    cursor: pointer;
    margin-top: 15px;
    transition: all 0.3s ease;
  }
  
  .game-story button:hover {
    background-color: #ff00ff;
    color: #fff;
  }