.comic-button {
  background-color: #ff00ff;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 20px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: bold;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.7);
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.comic-button:hover {
  background-color: #00ffff;
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.7);
}

.comic-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.comic-modal {
  background-color: #1a1a1a;
  padding: 30px;
  border-radius: 20px;
  max-width: 90%;
  max-height: 100%;
  overflow-y: auto;
  box-shadow: 0 0 50px rgba(255, 0, 255, 0.5);
}

.comic-modal h2 {
  color: #00ffff;
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  text-shadow: 2px 2px 4px rgba(0, 255, 255, 0.5);
}

.comic-container {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
}

.comic-panel {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

.comic-panel img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
}

.text-overlay p {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  margin: 0;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.comic-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.comic-controls button {
  background-color: #00ffff;
  color: #1a1a1a;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.comic-controls button:hover {
  background-color: #ff00ff;
  color: white;
}

.close-button {
  background-color: #ff00ff;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  transition: all 0.3s ease;
}

.close-button:hover {
  background-color: #00ffff;
  color: #1a1a1a;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}